
import imgsComp from './components/imgsComp.vue'
import swiperComp from './components/swiperComp.vue'
import videoComp from './components/videoComp.vue'
import footerBtn from './components/footerBtn.vue'
import config from './config'
import headerBar from '@/components/header/index.vue'

export default{
	setup(){
    let key = 'vertical'
    let detailImgs: any = config.detailImgs
    let swiperImgs: any = config.swiperImgs
    let otherImgs: any = config.otherImgs

		return {
      detailImgs: detailImgs[key],
      swiperImgs: swiperImgs[key],
      otherImgs: otherImgs[key],
      tips: config.tips,
      pageTitle: config.pageTitle
    }
	},
  components: {
    imgsComp,
    swiperComp,
    videoComp,
    footerBtn,
    headerBar
  }
}
