<template>
<div class="container">
  <headerBar :isshow="true" :title=pageTitle></headerBar>
  <videoComp :isVertical = 'true'></videoComp>
  <div class="detail">
    <!-- 图片循环 -->
    <imgsComp :imgs=detailImgs></imgsComp>
    <!-- swiperImgs -->
    <div class="swiper-content">
      <div class="swiper-content-title">使用方法</div>
      <swiperComp :imgs=swiperImgs :isVertical = 'true'></swiperComp>
      <div class="swiper-content-tips">
        <p v-for="item in tips" :key=item>{{item}}</p>
      </div>
    </div>
    <!-- 图片循环 -->
    <imgsComp :imgs=otherImgs></imgsComp>
  </div>
  <footerBtn :isVertical = 'true'></footerBtn>
</div>
</template>

<script lang="ts">
import imgsComp from './components/imgsComp.vue'
import swiperComp from './components/swiperComp.vue'
import videoComp from './components/videoComp.vue'
import footerBtn from './components/footerBtn.vue'
import config from './config'
import headerBar from '@/components/header/index.vue'

export default{
	setup(){
    let key = 'vertical'
    let detailImgs: any = config.detailImgs
    let swiperImgs: any = config.swiperImgs
    let otherImgs: any = config.otherImgs

		return {
      detailImgs: detailImgs[key],
      swiperImgs: swiperImgs[key],
      otherImgs: otherImgs[key],
      tips: config.tips,
      pageTitle: config.pageTitle
    }
	},
  components: {
    imgsComp,
    swiperComp,
    videoComp,
    footerBtn,
    headerBar
  }
}
</script>

<style lang="scss" scoped>
.container{
  padding: 12vw 0 3vw 0;
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  font-family: PingFangSC-Regular;
}
.detail{
  padding-bottom: 14vw;
}
.swiper-content{
  padding: 9vw 0 12vw 0;
  background-image: linear-gradient(180deg, 
		#e2f4ec 0%, 
		#ffffff 100%);
}
.swiper-content-title{
  font-family: PingFangSC-Semibold;
	font-size: 6vw;
	color: #179059;
  text-align: center;
  margin-bottom: 4vw;
}
.swiper-content-tips{
  margin: 0 8vw 0 9vw;
  font-family: PingFangSC-Medium;
	font-size: 4vw;
	line-height: 5vw;
	color: #3e3e3e;
  margin-top: 8vw;
  p{
    margin-top: 4vw;
  }
}
</style>